import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 102,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Poppins, sans-serif",
    secondary: "Signifier",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#1C1D1F",
      light: "#FFFFFF",
      dark: "#1C1D1F",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#F8F8F8",
      light: "#1F1D2F",
      dark: "#1F1D2F",
    },
    tertiary: {
      main: "#2C9ED0",
    },
    text: {
      primary: "#9B9B9B",
      secondary: "#686868",
      content: "#000000",
      tertiary: "#34393E",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Poppins", "Signifier", "Arial", sans-serif`,
  },
})

export default theme
