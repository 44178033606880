// import React, { useState } from "react"
//
// export const colorsContext = React.createContext()
//
// const Provider = props => {
//   const [savedColors, setSavedColors] = useState([])
//
//   return (
//     <colorsContext.Provider
//       value={{
//         savedColors,
//         addColor: color =>
//           setSavedColors(savedColors => [...savedColors, color]),
//       }}
//     >
//       {props.children}
//     </colorsContext.Provider>
//   )
// }
//
// export default ({ element }) => <Provider>{element}</Provider>
import React, { useState } from "react"

export const DrawerContext = React.createContext()
export const DrawerContextConsumer = DrawerContext.Consumer

const Provider = props => {
  const [isFormDrawerOpen, setIsFormDrawerOpen] = useState(false)
    const [isFormApplyOpen, setIsFormApplyOpen] = useState(false)

    // console.log(isFormDrawerOpen)

  return (
    <DrawerContext.Provider
      value={{
        isFormDrawerOpen,
        setIsFormDrawerOpen: isOpen => setIsFormDrawerOpen(isOpen),
          isFormApplyOpen,
          setIsFormApplyOpen: isOpen => setIsFormApplyOpen(isOpen),
      }}
    >
      {props.children}
    </DrawerContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
